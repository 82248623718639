import { useDispatch, useSelector} from 'react-redux'

import {addItemToCart} from '../../store/cart/cart.actions'
import {selectCartItems} from '../../store/cart/cart.selector'

import {ProductCardContainer, Footer, Name, Price} from './product-card.styles'
import Button, {BUTTON_TYPE_CLASSES} from "../button/button.component"

const ProductCard = ({ product }) => {
    const { name, imageUrl, price } = product
    const cartItems = useSelector(selectCartItems)
    const dispatch = useDispatch()
    const addProductToCart = () => dispatch(addItemToCart(cartItems, product))

    return(
        <ProductCardContainer>
            <img src={imageUrl} alt={`${name}` } />
            <Footer>
                <Name>{ name}</Name>
                <Price>{ price}</Price>
            </Footer>
            <Button buttonType={BUTTON_TYPE_CLASSES.inverted} onClick={addProductToCart}>Add to Cart</Button>
        </ProductCardContainer>
    )
}

export default ProductCard