import {Img, Item, ItemDetails} from './cart-item.styles.jsx'

const CartItem = ({ cartItem }) => {
    const { name, imageUrl, quantity, price} = cartItem
    return (
        <Item>
            <Img src={imageUrl} alt={`${name}`} />
            <ItemDetails>
                <span className='name'>{name}</span>
                <span className='price'>{quantity} x ${ price}</span>
            </ItemDetails>
        </Item>
    )
}

export default CartItem