import { useState, useEffect, Fragment } from 'react'
import {useParams} from 'react-router-dom'
import { useSelector } from "react-redux/es/exports";

import {selectCategoriesMap, selectCategoriesIsLoading} from '../../store/categories/categories.selector'

import ProductCard from '../../components/product-card/product-card.component'
import Spinner from '../../components/spinner/spinner.component';

import {Title, CategoryContainer} from './category.styles'

const Category = () => {
    const categoriesMap  = useSelector(selectCategoriesMap)
    const {category} = useParams()
    const isLoading = useSelector(selectCategoriesIsLoading)

    const  [products, setProducts] = useState(categoriesMap[category])

    useEffect(() => {
        setProducts(categoriesMap[category])
    }, [category, categoriesMap])

    return (
        <Fragment>
            <Title>{category.toUpperCase()}</Title>
            {
                isLoading ? <Spinner /> : <CategoryContainer>
                {products && products.map((product) => <ProductCard key={product.id} product={product} />)}
            </CategoryContainer>
            }
        </Fragment>
    )
}

export default Category