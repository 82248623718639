import Directory from '../../components/directory/directory.component'

import '../../components/directory/directory.styles';

const Home = () => {
    return (
        <Directory />
    )
};

export default Home